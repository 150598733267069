<template>
    <div class="menu-category-items" v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="containers mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/ClientPOS' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item>{{ $route.params.categoryName }} - Items</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Items in "{{ $route.params.categoryName }}"</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  placeholder="Search by Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
            </div>


              <article class="pt-5">
                <div class="menu-items-grid">
                  <div 
                    v-for="item in items" 
                    :key="item.MenuItemID"
                  >
                    <div>
                      <img
                        :src="item.ItemImage"
                      >
                      <div class="item-desc">
                        <div>
                          <div class="text-center">
                            <span>{{ item.ItemName }}</span>
                          </div>
                          <div @click="addItem(item)" class="text-center">
                            <el-tooltip
                              content="Add"
                              placement="top"
                            >
                              <el-button
                                type="success"
                                :disabled="false"
                                @click="addItem(item)"
                                icon="el-icon-plus"
                                circle
                              ></el-button>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pt-2 pb-2">
                      {{ item.ItemName }} (<small><strong>{{ formatCurrency(item.Price) }}</strong></small>)
                    </div>
                  </div>
                </div>
              </article>
            </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  // import ScaleOut from "@/components/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      // ScaleOut
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showUserEditDialog: false,
        items: [],
        loadingError: false,
        orderLists: [],
          
        current_currency: '',
        country: '',
        currency_code: '',
        kes_rate: '',
        tzs_rate: '',
        ssp_rate: '',
        rwf_rate: '',
        bif_rate: '',
        ugx_rate: '',
        usd_rate: '',
      };
    },
        
    watch: {
        '$route.params.CategoryID': 'getMenuItems'
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getMenuItems();
    },
  
    methods: {

      ...mapActions(['addItemToOrderList', 'loadOrderList']),

      addItem(MenuItem) {
        this.addItemToOrderList(MenuItem);
        this.loadOrderList();
      },

      showEditProductData(MenuItemID) {
        this.$router.push({
          path: `/POS/menu-category/${this.$route.params.CategoryID}/${this.$route.params.menuCategoryName}/edit-menu-item/${MenuItemID}`,
        });
      },
  
      closeEditUserData() {
        this.showUserEditDialog = false;
      },
  
      async getMenuItems() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `menu-categories/get-one/${this.$store.state.company.company_id}/${this.$route.params.CategoryID}`
          );
          if (request.data.success) {
            this.items = request.data.menuItems;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch menuItems now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },

      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      },

      formatCurrency(amount) {
        const currency = this.$store.state.currentCurrency
        // currency = 'USD';

        if (currency === 'KES') {
          const newAmount = Math.round((this.kes_rate / this.ugx_rate) * amount)
          return `KES ${this.formatNumber(newAmount)}`
        } else if (currency === 'UGX') {
          return `UGX ${this.formatNumber(amount)}`
        } else if (currency === 'TZS') {
          const newAmount = Math.round((this.tzs_rate / this.ugx_rate) * amount)
          return `TZS ${this.formatNumber(newAmount)}`
        } else if (currency === 'USD') {
          const newAmount = Math.round((this.usd_rate / this.ugx_rate) * amount)
          if (newAmount <= 1) {
            return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(3)}`
          }
          return `$${((this.usd_rate / this.ugx_rate) * amount).toFixed(2)}`
        } else if (currency === 'SSP') {
          const newAmount = Math.round((this.ssp_rate / this.ugx_rate) * amount)
          return `SSP ${this.formatNumber(newAmount)}`
        } else if (currency === 'RWF') {
          const newAmount = Math.round((this.rwf_rate / this.ugx_rate) * amount)
          return `RWF ${this.formatNumber(newAmount)}`
        } else if (currency === 'BIF') {
          const newAmount = Math.round((this.bif_rate / this.ugx_rate) * amount)
          return `BIF ${this.formatNumber(newAmount)}`
        } else {
          // default to UGX
          return `UGX ${this.formatNumber(amount)}`
        }
      },

      async getCurrencyRates() {
        try {
          const request = await this.$axios.get(
            'latest.json?app_id=8f4b23638b764037902c6e67e559a0d0', {
              baseURL: 'https://openexchangerates.org/api/'
            }
          )
          if (request.data) {
            this.currencyData = request.data
            // value for 1 dollar
            this.usd_rate = 1
            this.ugx_rate = this.currencyData.rates.UGX
            this.kes_rate = this.currencyData.rates.KES
            this.tzs_rate = this.currencyData.rates.TZS
            this.ssp_rate = this.currencyData.rates.SSP
            this.rwf_rate = this.currencyData.rates.RWF
            this.bif_rate = this.currencyData.rates.BIF
            this.isFetching = false
            this.isError = false
          }
        } catch (error) {
          // eslint-disable-line no-unused-vars
          this.isFetching = true
          this.isError = true
        } finally {
          this.loading = false
        }
      },

    },
  };
  </script>
  

  <style scoped>
  h1 {
    font-size: 1.8em;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  
  .menu-category-items {
      padding: 10px;
    }
    
    .menu-items-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;
    }

    
  .menu-items-grid > div > div:first-child{
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 200px;
    cursor: pointer;
    position: relative;
  }
  .menu-items-grid > div > div:first-child img{
    border-radius: 10px;
    width: 100%; 
    object-fit: cover;
    height: 200px;
    cursor: pointer;
    position: relative;
  }

  .menu-items-grid .item-desc{
    border-radius: 10px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    display: none;
  }

  
  .menu-items-grid > div > div:first-child:hover .item-desc{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
  }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
  }
  </style>